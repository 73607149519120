"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Fractions = void 0;
const React = __importStar(require("react"));
const Stack_1 = require("./stack/Stack");
const App_1 = require("./App");
const childComponent = (props, remainder) => {
    switch (props.viewMode) {
        case App_1.ViewMode.stack:
            return React.createElement(Stack_1.Stack, { fractions: props.fractions, remainder: remainder });
        default:
            return React.createElement(Stack_1.Stack, { fractions: props.fractions, remainder: remainder });
    }
};
const Fractions = (props) => {
    const remainder = 100 - props.fractions.map(fraction => fraction.percentage).reduce((a, b) => a + b, 0);
    return React.createElement("div", { className: "fraction-container box" }, childComponent(props, remainder));
};
exports.Fractions = Fractions;
