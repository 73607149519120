"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ParseFailure = exports.parse = void 0;
const abstract_failure_1 = require("./abstract_failure");
const regex = /(?<description>.+\s)?(?<value>-?\d+)/i;
class ParseFailure extends abstract_failure_1.AbstractFailure {
}
exports.ParseFailure = ParseFailure;
const errors = {
    couldntParse: new ParseFailure('Could not parse fraction string'),
    noValueGiven: new ParseFailure('No numeric value given')
};
const defaultDescription = 'something';
const validate = (value, constraints) => {
    if (value > constraints.max) {
        return `This part exceeds ${constraints.max} which makes it over budget. Try something smaller, like ${constraints.max} `;
    }
    if (value == 0) {
        return 'I cannot draw zero-sized bars, sorry';
    }
    if (value < 0) {
        return 'Negative values, really?';
    }
    return null;
};
const parse = (input, constraints) => {
    const parsed = input.match(regex);
    if (parsed == null) {
        return errors.couldntParse;
    }
    if (parsed.groups == null) {
        return errors.couldntParse;
    }
    if (parsed.groups.value == null) {
        return errors.noValueGiven;
    }
    if (isNaN(parseInt(parsed.groups.value, 10))) {
        return errors.noValueGiven;
    }
    const value = parseInt(parsed.groups.value, 10);
    const error = validate(value, constraints);
    if (error == null) {
        return {
            value: parseInt(parsed.groups.value, 10),
            description: (parsed.groups.description || defaultDescription).trim()
        };
    }
    else {
        return new ParseFailure(error);
    }
};
exports.parse = parse;
