"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViewMode = exports.App = void 0;
const React = __importStar(require("react"));
const NewPart_1 = require("./NewPart");
const Breakdown_1 = require("./Breakdown");
const Total_1 = require("./Total");
class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            parts: [],
            total: 40,
            viewMode: ViewMode.stack
        };
        this.addFraction = this.addFraction.bind(this);
        this.changeTotal = this.changeTotal.bind(this);
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            console.log("app mounted");
        });
    }
    addFraction(value, description) {
        this.setState((current, _) => ({
            parts: [
                ...current.parts,
                {
                    id: current.parts.length,
                    value: value,
                    description: description,
                    percentage: (value / current.total) * 100
                }
            ],
            total: current.total
        }));
    }
    changeTotal(newTotal) {
        this.setState((current, _) => ({
            parts: current.parts.map(({ id, value, description, percentage }) => ({
                id: id,
                value: value,
                description: description,
                percentage: (value / newTotal) * 100
            })),
            total: newTotal
        }));
    }
    remainder() {
        const usedSum = this.state.parts.map(parts => parts.value).reduce((a, b) => a + b, 0);
        return this.state.total - usedSum;
    }
    render() {
        return React.createElement("div", { className: "app-container container" },
            React.createElement(Total_1.Total, { total: this.state.total, changeTotal: this.changeTotal }),
            React.createElement(NewPart_1.NewPart, { add: this.addFraction, max: this.remainder() }),
            React.createElement(Breakdown_1.Breakdown, { parts: this.state.parts, viewMode: this.state.viewMode }));
    }
}
exports.App = App;
var ViewMode;
(function (ViewMode) {
    ViewMode[ViewMode["stack"] = 0] = "stack";
    ViewMode[ViewMode["square"] = 1] = "square";
})(ViewMode || (ViewMode = {}));
exports.ViewMode = ViewMode;
