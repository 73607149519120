"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NewPart = void 0;
const React = __importStar(require("react"));
const part_parser_1 = require("../lib/part-parser");
const Errors_1 = require("./Errors");
class NewPart extends React.Component {
    constructor(props) {
        super(props);
        this.state = { errors: [] };
        this.textInput = React.createRef();
        this.onSubmit = this.onSubmit.bind(this);
    }
    submit() {
        const inputValue = this.textInput.current.value;
        const part = part_parser_1.parse(inputValue, { max: this.props.max });
        if (part instanceof part_parser_1.ParseFailure) {
            this.setState({ errors: [part.message] });
        }
        else {
            this.setState({ errors: [] });
            this.props.add(part.value, part.description);
            this.textInput.current.value = '';
        }
    }
    hasErrors() {
        return this.state.errors.length > 0;
    }
    onSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        this.submit();
    }
    render() {
        return React.createElement("form", { action: "#", onSubmit: this.onSubmit.bind(this) },
            React.createElement("div", { className: "control " + this.hasErrors() ? 'is-danger' : '' },
                React.createElement("input", { autoFocus: true, className: "input is-primary", ref: this.textInput, placeholder: "Enter a new fraction, e.g. 'Daily standup 2'" }),
                React.createElement(Errors_1.Errors, { errors: this.state.errors })));
    }
}
exports.NewPart = NewPart;
